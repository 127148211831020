<template>
    <!-- HORIZONTAL LAYOUT -->
    <vx-card title="Create Work Order">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Work Order ID </span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="code" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Work Order Description</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="description" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Bill Of Material ID</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <multiselect class="selectExample" v-model="selectedBom" :options="optionBom" :multiple="false"
                            :filterable="true" :allow-empty="false" :group-select="false" :max-height="100" :limit="3"
                            :internal-search="false" :select-label="''" :deselect-label="''"
                            placeholder="Type to search" :searchable="true" track-by="id" label="Code"
                            @select="handleBoM()" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Purpose</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <!-- <multiselect class="selectExample" v-model="selectedBom" :options="optionBom" :multiple="false"
                            :filterable="true" :allow-empty="false" :group-select="false" :max-height="100" :limit="3"
                            :internal-search="false" :select-label="''" :deselect-label="''"
                            placeholder="Type to search" :searchable="true" track-by="id" label="Code"
                            @select="handleBoM()" /> -->
                        <multiselect :allowEmpty="false" :options="optionPurpose" v-model="selectedPurpose"
                            :select-label="''" :deselect-label="''" :label="'name'" :track-by="'name'" :placeholder="''"
                            :disabled="selectedBom ? true : false" @input="(val) => {
                                onChangePurpose(val);
                            }" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <multiselect class="selectExample" v-model="selectedWarehouse" :options="optionWarehouse"
                            :multiple="false" :select-label="''" :deselect-label="''" :allow-empty="false"
                            :group-select="false" :max-height="100" :limit="3" placeholder="Type to search"
                            :searchable="true" :custom-label="customLabelWarehouse" @input="(val) => {
                                onChangeWarehouse(val.WarehouseID);
                            }" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Warehouse Area</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <multiselect class="selectExample" v-model="selectedWarehouseArea"
                            :options="optionWarehouseArea" :multiple="false" :select-label="''" :deselect-label="''"
                            :allow-empty="false" :group-select="false" :max-height="100" :limit="3"
                            placeholder="Type to search" :searchable="true" :custom-label="customLabelWarehouseArea"
                            @input="(val) => {
                                onChangeWarehouseArea();
                            }" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Request Date</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="requestDate"
                            placeholder="Select Date" :disabledDates="disabledDatesRequest" :cleared="() => {
                                requestDate = null
                            }">
                        </datepicker>
                        <!-- <vs-input v-model="requestDate" class="w-full" type="date" name="requestDate"
                            :min="now"></vs-input> -->
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Execution Date</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="executionDate"
                            placeholder="Select Date" :disabledDates="disabledDatesExecution">
                        </datepicker>
                        <!-- <vs-input v-model="executionDate" class="w-full" type="date" name="executionDate"
                            :min="minExecutionDate"></vs-input> -->
                    </div>
                </div>
            </div>

            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Note</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-textarea v-model="note" class="w-full" type="date" name="note"></vs-textarea>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <label>Attachment
                            <small style="color: red">(only: jpg, jpeg, pdf, png)</small></label>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <div class="vx-col w-1/2">
                            <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
                            <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file"
                                multiple="multiple" accept=".jpg, .jpeg, .png, .pdf" />
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span></span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <div class="vx-col sm:w-4/5 w-full">
                            <vs-button class="mr-3 mb-2" @click="handleAttachment">Add Attachment</vs-button>
                        </div>
                    </div>
                </div>
                <!-- display: block -->
                <vs-divider style="width: 60%; margin-left: 30%">
                    List Attachment
                </vs-divider>
                <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile"
                                v-for="(tr, i) in fileAttachment">
                                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                                        <vs-button type="line" icon-pack="feather" icon="icon-eye"
                                            @click.stop="handleShowAttachment(tr)" />
                                    </vx-tooltip>
                                </td>

                                <td class="td vs-table--td">
                                    <template>
                                        <vx-tooltip text="Delete">
                                            <vs-button type="line" icon-pack="feather" icon="icon-trash"
                                                @click.stop="handleDeleteAttachment(i)" />
                                        </vx-tooltip>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>
        <h3><b>Parent (Resullt)</b></h3>
        <hr />
        <br />
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="25%">SKU Description</th>
                            <th width="5%">Qty</th>
                            <th width="5%">Unit</th>
                            <th width="5%">Qty UOM</th>
                            <th width="10%">Supplier</th>
                            <th width="10%">MAP</th>
                            <th width="10%">Sub Total</th>
                            <!-- <th width="15%">Value</th> -->
                            <!-- <th width="15%">Batch</th> -->
                        </tr>
                    </thead>
                    <tbody v-if="selectedBom">
                        <tr>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" :value="`${itemParent.sku_code} ${itemParent.name}`"
                                    disabled />
                            </td>
                            <!-- <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="itemParent.name" disabled />
                            </td> -->

                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" type="number" v-model="itemParent.qty" :min="1" />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="itemParent.unit" disabled />
                            </td>


                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" v-model="itemParent.qty_uom" disabled />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" v-model="itemParent.price" disabled />
                            </td>
                            <!-- <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="itemParent.optionBatch"
                                    v-model="itemParent.batch" :select-label="''" :deselect-label="''" :placeholder="''"
                                    :label="'batch'" :track-by="'batch'" />
                            </td> -->
                        </tr>

                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="itemParent.optionItem"
                                    v-model="itemParent.selected_items" :select-label="''" :deselect-label="''"
                                    :label="'label'" :track-by="'label'" :placeholder="''" @input="(val) => {
                                        onChangeSku(val, 0, 'parent');
                                    }
                                        " @search-change="(val) => {
                                            handleSearchLines(val, 0, 'parent');
                                        }
                                            " />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="number" v-model="itemParent.qty" @input="(val) => {
                                    onChangeQty(val, 0, 'parent');
                                }" :disabled="disableParentQty"></vs-input>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="itemParent.optionUnit"
                                    v-model="itemParent.unit" :select-label="''" :deselect-label="''" :placeholder="''"
                                    :label="'name'" :track-by="'name'" @input="(val) => {
                                        onChangeUnit(val, 0, 'parent');
                                    }
                                        " :disabled="disableParentUnit" />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="number" v-model="itemParent.qty_uom" readonly></vs-input>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="itemParent.optionSupplier"
                                    v-model="itemParent.supplier" :select-label="''" :deselect-label="''"
                                    :placeholder="''" :label="'supplier_code'" :track-by="'supplier_code'"
                                    :disabled="isDisableSupplier" @input="(val) => {
                                        onChangeSupplier(val)
                                    }" />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="text" readonly :min="0"
                                    :value="formattedItemParentPrice"></vs-input>
                            </td>
                            <!-- <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" :value="formattedItemParentPrice" v-model="itemParent.price"
                                    disabled />
                            </td> -->
                            <!-- <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="itemParent.optionBatch"
                                    v-model="itemParent.batch" :select-label="''" :deselect-label="''" :placeholder="''"
                                    :label="'batch'" :track-by="'batch'" />
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h3><b>Child (Source)</b></h3>
        <hr />
        <br />

        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="50%">SKU Description</th>
                            <!-- <th width="30%">SKU Name</th> -->
                            <th width="5%">Qty</th>
                            <th width="10%">Unit</th>
                            <th width="10%">Qty UOM</th>
                            <th width="10%">MAP</th>
                            <th width="20%">Batch</th>
                            <!-- <th width="10%">Value</th> -->
                            <th width="5%">Action</th>
                            <!-- <th width="5%"></th> -->
                        </tr>
                    </thead>
                    <tbody v-if="selectedBom">
                        <tr v-for="(child, index) in itemLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" :value="`${child.sku_code} ${child.name}`" disabled />
                            </td>
                            <!-- <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="itemLines[index].name" disabled />
                            </td> -->
                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" v-model="child.qty" disabled />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="child.unit" disabled />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="child.qty_uom" disabled />
                            </td>

                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="child.price" disabled />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="child.optionBatch" v-model="child.batch"
                                    :select-label="''" :deselect-label="''" :placeholder="''" :label="'batch'"
                                    :track-by="'batch'" />
                            </td>

                            <td class="td vs-table--td" style="text-align: center">
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="(child, index) in itemLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="child.optionItem"
                                    v-model="child.selected_items" :select-label="''" :deselect-label="''"
                                    :label="'label'" :track-by="'label'" :placeholder="''" @input="(val) => {
                                        onChangeSku(val, index, 'child');
                                    }
                                        " @search-change="(val) => {
                                            handleSearchLines(val, index, 'child');
                                        }
                                            " />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="number" v-model="child.qty" @input="(val) => {
                                    onChangeQty(val, index, 'child');
                                }
                                    "></vs-input>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="child.optionUnit" v-model="child.unit"
                                    :select-label="''" :deselect-label="''" :placeholder="''" @input="(val) => {
                                        onChangeUnit(val, index, 'child');
                                    }
                                        " :label="'name'" :track-by="'name'" />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="number" v-model="child.qty_uom" readonly></vs-input>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="text" :value="formattedItemChildPrice(child.price)"
                                    readonly :min="0"></vs-input>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="child.optionBatch" v-model="child.batch"
                                    :select-label="''" :deselect-label="''" :placeholder="''" :label="'batch'"
                                    :track-by="'batch'" />
                            </td>
                            <!-- <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="text" :value="formattedItemChildPrice(child.price)"
                                    readonly :min="0"></vs-input>
                            </td> -->
                            <td class="td vs-table--td" style="text-align: center">
                                <div class="vx-input-group flex">
                                    <vs-button v-if="itemLines.length > 1" @click.stop="removeRow(index)" size="small"
                                        color="danger" icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                        title="Remove Row" />
                                    <vs-button v-if="index == itemLines.length - 1" @click.stop="addRow(index)"
                                        size="small" color="success" icon-pack="feather" icon="icon-plus"
                                        title="Add Row"
                                        :disabled="!selectedPurpose || selectedPurpose.value == 0 ? true : false" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" color="primary" v-on:click="SubmitForm()">Submit</vs-button>
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack()">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    props: {
        title: {
            type: String,
        },
        action: {
            type: String,
        },
    },
    components: {
        Datepicker
    },
    mounted() {
        // this.getOptionItemAvailable();
        this.getBillOfMaterial();
        this.getWarehouse();
    },
    computed: {
        formattedItemParentPrice() {
            return new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(this.itemParent.price)
        },
        disabledDatesExecution() {
            return {
                to: moment(this.requestDate).toDate()
            }
        },
        disabledDatesRequest() {
            return {
                from: moment().toDate()
            }
        },
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
    watch: {
        // requestDate: function (val) {
        // this.minExecutionDate = moment(val).add(1, "days").format("YYYY-MM-DD");
        // this.executionDate = moment(val).format("YYYY-MM-DD");
        // },
        "itemParent.qty": function (val) {
            if (this.itemParent.qty > 0 && this.selectedBom) {
                // if (val % 2 == 0) {
                this.itemParent.optionBatch = []
                this.itemParent.batch = null

                this.itemLines = this.itemLines.map((el) => {
                    return {
                        item_unit_id: el.item_unit_id,
                        sku_code: el.sku_code,
                        name: el.name,
                        unit: el.unit,
                        qty_old: el.qty_old,
                        qty: Math.ceil((val / this.itemParent.qty_old) * el.qty_old),
                        qty_uom: el.qty_uom,
                        price: el.price,
                        batch: null,
                        optionBatch: []
                    };
                });
                // } else {
                //     val = val - 1;
                //     this.itemLines = this.itemLines.map((el) => {
                //         return {
                //             item_unit_id: el.item_unit_id,
                //             sku_code: el.sku_code,
                //             name: el.name,
                //             unit: el.unit,
                //             qty_old: el.qty_old,
                //             qty: Math.ceil((val / this.itemParent.qty_old) * el.qty),
                //         };
                //     });
                // }
            } else if (this.selectedBom) {
                this.itemLines = this.itemLines.map((el) => {
                    return {
                        item_unit_id: el.item_unit_id,
                        sku_code: el.sku_code,
                        name: el.name,
                        unit: el.unit,
                        qty_old: el.qty_old,
                        qty: 0,
                    };
                });
            }
        },
        "itemLines": {
            deep: true,
            async handler(newVal) {
                if (this.selectedPurpose.value == 0) {
                    const itemLineItem = newVal[0]
                    this.itemParent.qty = itemLineItem.qty
                    if (itemLineItem.unit) {
                        const foundUnit = this.itemParent.optionUnit.find(unit => unit.name == newVal[0].unit.name)
                        if (foundUnit) {
                            this.itemParent.unit = this.itemParent.optionUnit.find(unit => unit.name == newVal[0].unit.name)
                            console.log(this.itemParent.unit)
                            const data = await this.handleGetUnitUom(this.itemParent.sku_code, this.itemParent.unit.unit_id);
                            const uomDataSource = await this.handleGetUnitUom(this.itemLines[0].selected_items.sku_code, this.itemLines[0].unit.unit_id)

                            this.itemLines[0].qty_uom = uomDataSource

                            if (data != uomDataSource) {
                                this.$vs.notify({
                                    title: "Error",
                                    text: "Qty UOM SKU result and source not same",
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });

                                this.itemParent.unit = null
                                this.itemParent.qty_uom = 0
                                return
                            }

                            this.itemParent.qty_uom = data
                            const currUOM = data
                            const currMAP = this.itemParent.price
                            const currQTY = this.itemParent.qty

                            const subTotal = currUOM * currMAP * currQTY

                            const subTotalText = new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 7,
                                maximumFractionDigits: 7,
                            }).format(subTotal)

                            this.itemParent.subTotal = subTotal
                            this.itemParent.subTotalText = subTotalText
                        } else {
                            this.$vs.notify({
                                title: "Error",
                                text: "Parent unit not found",
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check",
                            });

                            return
                        }
                    } else {
                        const currUOM = this.itemParent.qty_uom
                        const currMAP = this.itemParent.price
                        const currQTY = this.itemParent.qty

                        const subTotal = currUOM * currMAP * currQTY
                        this.itemParent.subTotal = subTotal
                        const subTotalText = new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 7,
                            maximumFractionDigits: 7,
                        }).format(subTotal)

                        this.itemParent.subTotal = subTotal
                        this.itemParent.subTotalText = subTotalText
                    }
                }
            }
        }
    },
    data: () => ({
        optionItem: [],
        isDisableSupplier: true,
        optionPurpose: [
            {
                value: 0,
                name: "SKU-ID Change",
            },
            {
                value: 1,
                name: "Repackaging",
            },
        ],
        disableParentQty: false,
        disableParentUnit: false,
        selectedPurpose: null,
        itemLines: [
            {
                item_unit_id: null,
                sku_code: "",
                name: "",
                unit: "",
                qty: 0,
                qty_old: 0,
                optionItem: [],
                optionUnit: [],
                selected_items: null,
                qty_uom: 0,
                batch: null,
                optionBatch: [],
                price: 0,
            },
        ],
        itemParent: {
            optionSupplier: [],
            supplier: null,
            optionItem: [],
            optionUnit: [],
            item_unit_id: null,
            sku_code: "",
            name: "",
            unit: "",
            qty: 0,
            qty_old: 0,
            price: 0,
            qty_uom: 0,
            batch: null,
            selected_items: null,
            optionBatch: []
        },
        optionBom: [],
        selectedBom: null,
        selectedWarehouse: null,
        optionWarehouse: [],
        selectedWarehouseArea: null,
        optionWarehouseArea: [],
        requestDate: moment().format("YYYY-MM-DD"),
        minExecutionDate: moment().add(1, "days").format("YYYY-MM-DD"),
        executionDate: moment().format("YYYY-MM-DD"),
        start: 1,
        end: 0,
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        code: "",
        description: "",
        note: "",
        attachment: "",
        fileAttachment: [],
        IDAttachment: [],
        now: moment().add(0, "days").format("YYYY-MM-DD"),
        IDs: null,
        isDisabled: true,
        allowedExtension: ["jpg", "jpeg", "png", "pdf"]
    }),
    methods: {
        resetItemLines() {
            this.itemLines = [
                {
                    item_unit_id: null,
                    sku_code: "",
                    name: "",
                    unit: "",
                    qty: 0,
                    qty_old: 0,
                    optionItem: [],
                    optionUnit: [],
                    selected_items: null,
                    qty_uom: 0,
                    batch: null,
                    optionBatch: [],
                    price: 0,
                },
            ];
        },
        resetItemParent() {
            this.itemParent = {
                optionSupplier: [],
                supplier: null,
                optionItem: [],
                optionUnit: [],
                item_unit_id: null,
                sku_code: "",
                name: "",
                unit: "",
                qty: 0,
                qty_old: 0,
                price: 0,
                qty_uom: 0,
                batch: null,
                selected_items: null,
                optionBatch: []
            }
        },
        formattedItemChildPrice(price) {
            return new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(price)
        },
        handleBack() {
            this.$router.push({
                name: "work-order-ho",
            });
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        containsKey(obj, key) {
            return Object.keys(obj).includes(key);
        },
        SubmitForm() {
            if (!this.description) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please input Work Order Description",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return
            }

            if (!this.selectedPurpose) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select purpose work order",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return false
            }
            // if (!this.selectedBom || !this.optionBom.length) {
            //     this.$vs.notify({
            //         title: "Error",
            //         text: "Please select Bill of Material",
            //         color: "danger",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-check",
            //     });
            //     return;
            // }

            if (!this.selectedWarehouse) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Warehouse",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (!this.selectedWarehouseArea) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Warehouse Area",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (!this.fileAttachment.length) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please input work order attachment",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.fileAttachment.some(a => !this.allowedExtension.includes(a.NameFile.split(".").at(-1).toLowerCase()))) {
                this.$vs.notify({
                    title: "Error",
                    text: "Invalid attachments extention",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.itemParent.sku_code == "") {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select item parent first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.itemParent.qty < 1 || parseFloat(this.itemParent.qty) <= 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "SKu Result Quantity must greater than 0",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.itemParent.unit) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select unit sku result",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.itemParent.optionSupplier.length && !this.itemParent.price) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "The MAP or DBP price must not be zero.",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.itemParent.optionSupplier.length && !this.itemParent.supplier) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select supplier sku result",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.itemLines.some(item => item.sku_code == "")) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Item Line first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.itemLines.some(item => item.qty < 1)) {
                this.$vs.notify({
                    title: "Error",
                    text: 'Quantity must be greater than zero',
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.itemLines.some(item => item.sku_code == this.itemParent.sku_code)) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "The SKU code of the parent and child items must not be the same.",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.itemLines.some(item => item.unit == null)) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select item child unit",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }


            if (this.itemLines.some(item => item.batch == null)) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select item child batch",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            // if (this.optionPurpose === 0 &) {

            const ibomid = this.selectedBom ? this.selectedBom.id : null
            const ibomCode = this.selectedBom ? this.selectedBom.Code : null

            let payload = {}

            if (this.selectedBom) {
                payload = {
                    note: this.note,
                    description: this.description,
                    item_unit_id: Number(this.itemParent.item_unit_id),
                    sku_code: this.itemParent.sku_code,
                    name: this.itemParent.name,
                    unit: this.itemParent.unit,
                    quantity: Number(this.itemParent.qty),
                    default_quantity: Number(this.itemParent.qty_old),
                    price: Number(this.itemParent.price),
                    warehouse_id: this.selectedWarehouse.WarehouseID,
                    warehouse_name: this.selectedWarehouse.WarehouseName,
                    item_bill_of_material_id: ibomid,
                    item_bom_code: ibomCode,
                    warehouse_area_id: this.selectedWarehouseArea.WarehouseAreaID,
                    qty_uom: this.itemParent.qty_uom,
                    // batch: this.itemParent.batch.batch,
                    request_date: this.requestDate,
                    execution_date: this.executionDate,
                    purpose_type: this.selectedPurpose.value,
                    item_sku: this.itemLines.map((el) => {
                        return {
                            item_unit_id: Number(el.item_unit_id),
                            sku_code: el.sku_code,
                            sku_name: el.name,
                            unit_name: el.unit,
                            qty: Number(el.qty),
                            quantity_real: Number(el.qty_old),
                            qty_uom: el.qty_uom,
                            price: Number(el.price),
                            batch: el.batch.batch
                        };
                    }),
                    status: 1,
                }
            } else {
                payload = {
                    note: this.note,
                    description: this.description,
                    item_unit_id: Number(this.itemParent.unit.id),
                    sku_code: this.itemParent.selected_items.sku_code,
                    name: this.itemParent.selected_items.item_name,
                    unit: this.itemParent.unit.name,
                    quantity: Number(this.itemParent.qty),
                    default_quantity: Number(this.itemParent.qty_old),
                    price: Number(this.itemParent.price),
                    warehouse_id: this.selectedWarehouse.WarehouseID,
                    warehouse_name: this.selectedWarehouse.WarehouseName,
                    item_bill_of_material_id: ibomid,
                    item_bom_code: ibomCode,
                    warehouse_area_id: this.selectedWarehouseArea.WarehouseAreaID,
                    qty_uom: this.itemParent.qty_uom,
                    // batch: this.itemParent.batch.batch,
                    request_date: this.requestDate,
                    execution_date: this.executionDate,
                    purpose_type: this.selectedPurpose.value,
                    supplier_code: this.itemParent.supplier ? this.itemParent.supplier.supplier_code : null,
                    item_sku: this.itemLines.map((el) => {
                        return {
                            item_unit_id: Number(el.unit.id),
                            sku_code: el.sku_code,
                            sku_name: el.selected_items.item_name,
                            unit_name: el.unit.name,
                            qty: Number(el.qty),
                            quantity_real: Number(el.qty_old),
                            qty_uom: el.qty_uom,
                            price: Number(el.price),
                            batch: el.batch.batch
                        };
                    }),
                    status: 1,
                }
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to save this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("api/wms/v1/work-order/create", payload)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    title: "Success",
                                    text: "Work Order has been added",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                                this.IDs = resp.data;
                                this.handleSubmitAttachment();
                            } else {
                                this.attachment = [];
                                this.$vs.notify({
                                    title: "Error",
                                    text: resp.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                            }
                            this.$vs.loading.close();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        mappingAttachment() {
            this.adjustment.Attachment.map((v) => {
                this.fileAttachment.push({
                    ListID: v.ListID,
                    NameFile: v.NameFile,
                    PathFile: v.PathFile,
                });
            });
        },
        addRow(index) {
            this.itemLines.push({
                id: 0,
                optionItem: [],
                selected_items: null,
                sku_code: null,
                item_name: null,
                label: null,
                optionUnit: [],
                unit: null,
                qty: 0,
                qty_uom: 0,
                optionBatch: [],
                batch: null
            });
            const { value: purpose } = this.selectedPurpose


            const { WarehouseID, WarehouseAreaID } = this.selectedWarehouseArea
            this.searchSku("", index + 1, "child", purpose, WarehouseID, WarehouseAreaID);
        },
        removeRow(index) {
            this.itemLines.splice(index, 1);
        },
        handleAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                // this.fileAttachment = [];
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    // this.uploadData(i);
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                }
                document.querySelector("#fileInput").value = "";
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
        handleShowAttachment(file) {
            let objectURL;
            // console.log(file)
            objectURL = URL.createObjectURL(file.File);

            // link.download = file.name; // this name is used when the user downloads the file
            var href = objectURL;
            window.open(href, "_blank").focus();
        },
        handleDeleteAttachment(index) {
            // const deleteAttachment =this.fileAttachment[index]
            this.fileAttachment = this.fileAttachment.filter((r, i) => {
                return i != index;
            });
        },
        handleSubmitAttachment() {
            // console.log(this.fileAttachment);

            this.$vs.loading();
            if (this.fileAttachment.length > 0) {
                const formData = new FormData();
                for (let index = 0; index < this.fileAttachment.length; index++) {
                    formData.append("attachments[]", this.fileAttachment[index].File);
                }
                formData.append("work_order_id", this.IDs);

                this.$http
                    .post("api/wms/v1/work-order/create-attachment", formData)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.handleBack();
                            this.$vs.loading.close();
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Failed",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.$vs.loading.close();
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            } else {
                this.$vs.loading.close();
            }
        },
        getBillOfMaterial() {
            this.$vs.loading();
            this.$http
                .get("api/wms/v1/work-order/item-bill-of-material", {
                    params: {
                        length: null,
                        order: "id",
                        sort: "asc",
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            this.optionBom = resp.data.records;
                            if (this.optionBom.length > 0) {
                                // this.selectedBom = this.optionBom[0];
                            } else {
                                this.optionBom = [];
                                this.selectedBom = {};
                            }
                        } else {
                            this.optionBom = [];
                            this.selectedBom = {};
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        getWarehouse() {
            this.$vs.loading();
            this.$http
                .get("api/wms/v1/work-order/warehouse-by-user", {
                    params: {
                        order: "name",
                        sort: "asc",
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data) {
                            this.optionWarehouse = resp.data;
                            if (this.optionWarehouse.length > 0) {
                                // this.selectedWarehouse = this.optionWarehouse[0];
                            } else {
                                this.optionWarehouse = [];
                                this.selectedWarehouse = {};
                            }
                        } else {
                            this.optionWarehouse = [];
                            this.selectedWarehouse = {};
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        getWarehouseArea(warehouseID) {
            this.$vs.loading();
            this.$http
                .get("api/wms/v1/work-order/warehouse-area-by-warehouse", {
                    params: {
                        order: "name",
                        sort: "asc",
                        warehouseID
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data) {
                            this.optionWarehouseArea = resp.data;
                            if (this.optionWarehouseArea.length < 0) {
                                this.optionWarehouseArea = [];
                                this.selectedWarehouseArea = {};
                            }
                        } else {
                            this.optionWarehouseArea = [];
                            this.selectedWarehouseArea = {};
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        getBatch(itemUnitId, skuCode, type, index) {
            this.$vs.loading();
            this.$http
                .get("api/wms/v1/work-order/get-batch", {
                    params: {
                        sort: "asc",
                        warehouseID: this.selectedWarehouseArea.WarehouseID,
                        warehouseAreaID: this.selectedWarehouseArea.WarehouseAreaID,
                        skuCode: skuCode,
                        itemUnitID: itemUnitId
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (type == "child") {
                            if (resp.code == 200) {
                                if (resp.data) {
                                    this.itemLines[index].optionBatch = resp.data;
                                    if (this.itemLines[index].optionBatch.length < 0) {
                                        this.itemLines[index].optionBatch = [];
                                        this.itemLines[index].batch = null;
                                    }
                                } else {
                                    this.itemLines[index].optionBatch = [];
                                    this.itemLines[index].batch = null;
                                }
                            } else {
                                this.itemLines[index].optionBatch = [];
                                this.itemLines[index].batch = null;
                            }
                        } else {
                            if (resp.code == 200) {
                                if (resp.data) {
                                    this.itemParent.optionBatch = resp.data;
                                    if (this.itemParent.optionBatch.length < 0) {
                                        this.itemParent.optionBatch = [];
                                        this.itemParent.batch = null;
                                    }
                                } else {
                                    this.optionBatch = [];
                                    this.itemParent.optionBatch = [];
                                    this.itemParent.batch = null;
                                }
                            } else {
                                this.itemParent.optionBatch = [];
                                this.itemParent.batch = null;
                            }
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        customLabelWarehouse({ WarehouseCode, WarehouseName }) {
            return `${WarehouseCode} - ${WarehouseName}`;
        },
        customLabelWarehouseArea({ WarehouseAreaCode, WarehouseAreaName }) {
            return `${WarehouseAreaCode} - ${WarehouseAreaName}`;
        },
        handleBoM() {
            if (this.selectedBom) {
                this.selectedPurpose = this.optionPurpose.find(option => option.value === this.selectedBom.Purpose)
                this.itemParent = [];

                this.itemParent = {
                    item_unit_id: this.selectedBom.ItemUnitID,
                    sku_code: this.selectedBom.SkuCode,
                    name: this.selectedBom.Name,
                    unit: this.selectedBom.Unit,
                    qty: this.selectedBom.Quantity,
                    qty_old: this.selectedBom.Quantity,
                    price: 0,
                    qty_uom: this.selectedBom.amount_uom
                };

                this.itemLines = this.selectedBom.ItemBillOfMaterialLines.map((el) => {
                    return {
                        item_unit_id: el.ItemUnitID,
                        sku_code: el.SkuCode,
                        name: el.Name,
                        unit: el.Unit,
                        qty: el.Quantity,
                        qty_old: el.Quantity,
                        qty_uom: el.amount_uom,
                        price: 0,
                    };
                });

                this.selectedWarehouse = null
                this.selectedWarehouseArea = null
            } else {
                this.isDisabled = true;
            }
        },
        handleSearchLines(val, index, type) {
            if (this.timeOuts) {
                clearTimeout(this.timeOuts);
            }
            if (!this.selectedPurpose || !this.selectedWarehouse) {
                return
            }

            const { WarehouseID, WarehouseAreaID } = this.selectedWarehouseArea
            const { value } = this.selectedPurpose
            this.timeOuts = setTimeout(this.searchSku(val, index, type, value, WarehouseID, WarehouseAreaID), 500);
        },
        handleGetUnitOptions(skuCode, index, type) {
            this.$http
                .get("api/wms/v1/item-bill-of-material/unit-options", {
                    params: {
                        sku_code: skuCode,
                    },
                })
                .then((resp) => {
                    if (type == "child") {
                        if (resp.code == 200) {
                            this.itemLines[index].optionUnit = resp.data.option_units;
                        } else {
                            this.itemLines[index].optionUnit = [];
                        }
                    } else {
                        if (resp.code == 200) {
                            this.itemParent.optionUnit = resp.data.option_units;
                        } else {
                            this.itemParent.optionUnit = [];
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        handleGetUnitUom(skuCode, unit, index, type) {
            this.$http
                .get("api/wms/v1/item-bill-of-material/unit-uom", {
                    params: {
                        sku_code: skuCode,
                        unit: unit,
                    },
                })
                .then((resp) => {
                    if (type == "child") {
                        if (resp.code == 200) {
                            this.itemLines[index].qty_uom = resp.data;
                        } else {
                            this.itemLines[index].qty_uom = 0;
                        }
                    } else {
                        if (resp.code == 200) {
                            this.itemParent.qty_uom = resp.data;
                        } else {
                            this.itemParent.qty_uom = 0;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onChangeWarehouse(warehouseID) {
            this.getWarehouseArea(warehouseID)
        },
        async onChangeWarehouseArea() {
            const { value } = this.selectedPurpose
            const { WarehouseID, WarehouseAreaID } = this.selectedWarehouseArea
            this.searchSku("", 0, "parent", value, WarehouseID, WarehouseAreaID);
            this.searchSku("", 0, "child", value, WarehouseID, WarehouseAreaID);

            // if (this.selectedBom) {
            //     const { SkuCode, ItemBillOfMaterialLines, ItemUnitID } = this.selectedBom
            //     this.getBatch(ItemUnitID, SkuCode, "parent")
            //     // const priceType = "parent"
            //     // const { value: purposeType } = this.selectedPurpose
            //     // const { WarehouseID, WarehouseAreaID } = this.selectedWarehouseArea
            //     // const data = await this.getPrice(SkuCode, priceType, purposeType, WarehouseID, WarehouseAreaID)
            //     // this.itemParent.price = data.records.length > 0 ? data.records[0].price : 0

            //     ItemBillOfMaterialLines.forEach(async (item, index) => {
            //         const skuCode = item.SkuCode
            //         const itemUnitId = item.ItemUnitID
            //         // const priceType = "child"
            //         // // const data = await this.getPrice(skuCode, priceType, purposeType, WarehouseID, WarehouseAreaID)
            //         // const price = data.records.length > 0 ? data.records[0].price : 0

            //         this.getBatch(itemUnitId, SkuCode, "child", index)
            //         // this.itemLines = this.itemLines.map(line => {
            //         //     if (line.sku_code === skuCode) {
            //         //         return { ...line, price };
            //         //     }
            //         //     return line;
            //         // });
            //     })
            // }
        },
        onChangeQty(val, index, type) {
            if (val < 1) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Qty must be greater than zero",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });

                if (type == "child") {
                    this.itemLines[index].qty = 0;
                } else {
                    this.itemParent.qty = 0;
                }
            }

        },
        onChangeUnit(val, index, type) {
            const { unit_id, id } = val
            if (type == "child") {
                this.itemLines[index].qty_uom = 0;
                this.handleGetUnitUom(this.itemLines[index].sku_code, unit_id, index, type);
                this.getBatch(id, this.itemLines[index].sku_code, type, index)
            } else {
                this.itemParent.qty_uom = 0;
                this.handleGetUnitUom(this.itemParent.sku_code, unit_id, 0, type);
                this.getBatch(id, this.itemParent.sku_code, type)
            }
        },
        async onChangeSku(val, index, type) {
            if (type == "child") {
                this.itemLines[index].optionUnit = [];
                this.itemLines[index].unit = null;
                this.itemLines[index].qty_uom = 0;
                this.itemLines[index].qty_old = 0;
                this.itemLines[index].sku_code = val.sku_code;
                this.itemLines[index].item_name = val.item_name;
                this.itemLines[index].label = val.label;
                this.itemLines[index].price = val.price;
                // this.itemLines[index].price = val.source_value ? 0 : val.price;
                // if (val.source_value) {
                //     const { WarehouseID } = this.selectedWarehouseArea
                //     const data = await this.getSupplier(val.sku_code, WarehouseID)
                //     console.log(data)
                // }
                this.handleGetUnitOptions(val.sku_code, index, type);
            } else {
                this.itemParent.optionUnit = [];
                this.itemParent.unit = null;
                this.itemParent.qty_uom = 0;
                this.itemParent.qty_old = 0;
                this.itemParent.sku_code = val.sku_code;
                this.itemParent.item_name = val.item_name;
                this.itemParent.label = val.label;
                this.itemParent.price = val.source_value ? 0 : val.price
                this.isDisableSupplier = val.source_value ? false : true
                this.itemParent.subTotal = 0
                this.itemParent.subTotalText = "0"
                this.itemParent.supplier = null;
                this.itemParent.optionSupplier = []


                if (this.selectedPurpose.value == 0) {
                    this.itemLines.forEach(item => {
                        item.unit = null
                        item.qty_uom = 0
                        item.subTotal = 0
                        item.subTotalText = "0"
                        item.batch = null
                        item.optionBatch = []
                    })

                    const arrOfSubTotal = this.itemLines.map(item => item.qty * item.qty_uom * item.price)
                    const total = arrOfSubTotal.reduce((a, b) => a + b, 0)
                    const totalText = new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 7,
                        maximumFractionDigits: 7,
                    }).format(total)
                    this.total = totalText
                }

                if (val.source_value) {
                    const { WarehouseID } = this.selectedWarehouseArea
                    const data = await this.getSupplier(val.sku_code, WarehouseID)
                    this.itemParent.optionSupplier = data.records
                }
                this.handleGetUnitOptions(val.sku_code, 0, type);
            }
        },
        onChangeSupplier(val) {
            this.itemParent.price = val.price

            const currUOM = this.itemParent.qty_uom
            const currMAP = this.itemParent.price
            const currQTY = this.itemParent.qty
            const subTotal = currUOM * currMAP * currQTY

            this.itemParent.subTotal = subTotal

            const subTotalText = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(subTotal)

            this.itemParent.subTotalText = subTotalText
        },
        onChangePurpose(val) {
            const { value } = val
            // this.searchSku("", 0, "parent", value);
            // this.searchSku("", 0, "child", value);

            this.resetItemParent()
            this.resetItemLines()
            this.selectedWarehouse = null
            this.selectedWarehouseArea = null

            if (value == 0) {
                //SKU ID CHANGE
                this.disableParentQty = true
                this.disableParentUnit = true
            } else {
                // REPACAKING
                this.disableParentQty = false
                this.disableParentUnit = false
            }
        },
        searchSku(search, index, type, purposeType, warehouseID, WarehouseAreaID) {
            let skuType = "Result"
            if (type === 'child') {
                purposeType = 0
                skuType = "Source"
            }

            this.$http
                .get("api/wms/v1/work-order/item-material", {
                    params: {
                        type,
                        search,
                        purposeType,
                        warehouseID,
                        WarehouseAreaID,
                        skuType
                    },
                })
                .then((resp) => {
                    if (type == "child") {
                        if (resp.code == 200) {
                            if (resp.data.records) {
                                this.itemLines[index].optionItem = resp.data.records;
                            } else {
                                this.itemLines[index].optionItem = [];
                            }
                        } else {
                            this.itemLines[index].optionItem = [];
                        }
                    } else {
                        if (resp.code == 200) {
                            if (resp.data.records) {
                                this.itemParent.optionItem = resp.data.records;
                            } else {
                                this.itemParent.optionItem = [];
                            }
                        } else {
                            this.itemParent.optionItem = [];
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getSupplier(skuCode, warehouseID) {
            this.$vs.loading();
            try {
                const response = await this.$http
                    .get("api/wms/v1/work-order/supplier-price", {
                        params: {
                            sku_code: skuCode,
                            warehouse_id: warehouseID
                        },
                    })
                return response.data
            } catch (error) {
                console.log(error)
            } finally {
                this.$vs.loading.close();
            }
        },
        getPrice(search, type, purposeType, warehouseID, WarehouseAreaId) {
            if (type === 'child') {
                purposeType = 0
            }
            // this.$vs.loading();
            return new Promise((resolve, reject) => {
                this.$http
                    .get("api/wms/v1/item-bill-of-material/items", {
                        params: {
                            type: type,
                            search: search,
                            purposeType,
                            warehouseID,
                            WarehouseAreaId,
                            requestType: "Work Order"
                        },
                    })
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })

        }
    },
};
</script>
